<style lang="less"> 
    .my-address .van-button:first-child{
        background:linear-gradient(90deg,rgba(255,200,13,1) 0%,rgba(255,130,0,1) 100%);
        border: none;
    }
    
</style>
<template>
    <div class="my-address gray-bg" v-htmltit data-title="我的地址">
        <go-back-btn></go-back-btn>
        <van-address-edit
        ref="address"
        default-tag-text="默认"
        :area-list="areaList"
        show-search-result
        :address-info="addressInfo"
        :show-delete="type == 2?true:false"
        :show-set-default="true"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @change-area="changeArea"
        @save="onSave"
        @delete="onDelete"
        />
    </div>
</template>
<script>
import area from "@/libs/area.js"
import goBackBtn from "@/view/Public/goBackBtn"
import { addAddress ,delAddress } from "@/apis/api"
import { isEmojiCharacter } from "@/libs/utils"
export default {
    components:{ goBackBtn },
    name:'my-address',
    data() {
        return {
            type:1, //1 是新增 2 是编辑
            areaList:area,
            addressInfo:{}, //编辑时的默认地址
        }
    },
    mounted(){
        this.type = this.$route.params.type
        if(this.type == 2){
            let editAddress = JSON.parse(localStorage.getItem('editAddress'))
            this.addressInfo = {
                id:editAddress.id,
                name:editAddress.name,
                tel:editAddress.tel,
                province:editAddress.province,
                city:editAddress.city,
                county:editAddress.county,
                areaCode:editAddress.areaCode,
                addressDetail:editAddress.area,
                isDefault:editAddress.isDefault,
            }
        }
    },
    methods: {
        //新增和修改地址
        onSave(content) {
            if(this.type == 1){
                let data = {
                    consignee:content.name,
                    phone:content.tel,
                    areaCode:content.areaCode,
                    area:content.province + content.city + content.county,
                    address:content.addressDetail,
                    isdefault:content.isDefault?1:0,
                    address_str: content.province + ',' + content.city + ',' + content.county + ',' + content.addressDetail
                }
                addAddress(data).then(res=>{
                    if(res.code == 0){
                        this.$toast('新增地址成功')
                        this.$router.back(-1)
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }else{
                let data = {
                    id:content.id,
                    consignee:content.name,
                    phone:content.tel,
                    areaCode:content.areaCode,
                    area:content.province + content.city + content.county,
                    address:content.addressDetail,
                    isdefault:content.isDefault?1:0,
                    address_str: content.province + ',' + content.city + ',' + content.county + ',' + content.addressDetail
                }
                addAddress(data).then(res=>{
                    if(res.code == 0){
                        this.$toast('修改地址成功')
                        this.$router.back(-1)
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }
           
        },
        changeArea(){
            //修改地区的时候 将详细地址置为空
            this.$refs.address.setAddressDetail("")
        },
        onDelete(content){
            let data = {
                id:[content.id]
            }
            delAddress(data).then(res=>{
                if(res.code == 0){
                    this.$toast('删除地址成功')
                    this.$router.back(-1)
                }else{
                    this.$toast(res.msg)
                }
            })
        }
    }
}
</script>